import { Link, useNavigate, Navigate } from "react-router-dom";
import React, { useState } from "react";
import DataTable from "../../components/dataTable/DataTable";
import moment from "moment";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../db/firebase";
import { useAuth } from "../../auth/authContext";

const columns = [
  { field: "name", type: "string", headerName: "Name", width: 200 },
  { field: "subject", type: "string", headerName: "Subject", width: 200 },
  { field: "message", type: "string", headerName: "Message", width: 350 },
  { field: "email", type: "string", headerName: "Email", width: 200 },
  {
    field: "createdDate",
    type: "string",
    headerName: "Created Date",
    width: 200,
    renderCell: (params) => {
      if (params.row.createdDate) {
        const date = params.row.createdDate.toDate();
        return moment(date).format("MM/DD/YYYY");
      }
    },
  },
];

const ContactList = () => {

  const { userLoggedIn } = useAuth();

  const navigate = useNavigate();
  const [data, setData] = useState();

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "contactdata"));
      const contactList = querySnapshot.docs.map((doc) => doc.data());
      console.log(contactList);
      setData(contactList);
      //return { contactList };
    } catch (error) {
          console.error("Error fetching contacts:", error);
    }
  };

  if (userLoggedIn) {
    fetchData();
  } else {
    navigate("/login");
  }

  return (
    <>
      {!userLoggedIn && <Navigate to={"/login"} replace={true} />}

      <div class="welcome_area">
        <div class="containter">
          <div class="row justify-content-center">
            <div class="col-sm-8">
              <h2 id="quote">Contact List</h2>
            </div>
            <div class="col-sm-2">
              <button type="button" class="boxed-btn3-white-2 pull-right">
                <Link to="/admin/adminhome">Go Back</Link>
              </button>
            </div>
            <div class="col-xl-10 col-lg-10">
              <div className="ContactList">
                <DataTable slug="ContactList" columns={columns} rows={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactList;
