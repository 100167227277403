import { useEffect } from "react";
import { Link } from "react-router-dom";

const WhySVHC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="bradcam_area breadcam_bg bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="bradcam_text">
                <h3>Why SVHC</h3>
                <p>
                  <Link to="/home">Home /</Link> Why us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="about_info">
                <h2>Why SV Holistic Care</h2>
                <p>
                  SV Holistic Care offers acupuncture and nutrition counseling
                  services for chronic health conditions prevalent in the local
                  area. We carefully listen to our clients&#39; health concerns,
                  comprehensively evaluate, and identify the main cause of the
                  issue and offer nutritional assessments and strategies to
                  optimize their health. We offer pain management services to
                  clients experiencing bodily discomfort through the utilization
                  of acupuncture and cupping techniques. The integration of
                  acupuncture and nutrition services enables the provision of
                  pain relief and holistic health support to clients, promoting
                  a seamless and comprehensive experience with a single
                  healthcare provider.
                </p>
                <br />
                <h4>Our Services</h4>
                <ul>
                  <li>Nutrition Counseling</li>
                  <li>Acupuncture</li>
                  <li>Homeopathy</li>
                </ul>
                <Link to="/services" class="boxed-btn3-white-2">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhySVHC;
