import { useEffect } from "react";
import { auth } from "../db/firebase";

export default function Logout() {
  async function signOut() {
    auth.signOut();
    window.close();
  }

  useEffect(() => {
    signOut();
  }, []);
  return null;
}
