import { useEffect } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import NutritionImg from "../img/department/1.png";
import AcupunctureImg from "../img/department/2.png";
import HomeopathyImg from "../img/department/3.png";

const Home = () => {
  const options = {
    items: 1,
    nav: false,
    dots: false,
    rewind: true,
    autoplay: true,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="slider_area">
        <OwlCarousel options={options}>
          <div class="single_slider  d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="slider_text ">
                    <h3>
                      {" "}
                      <span>Nutrition Counseling</span>
                    </h3>
                    <p>
                      We provide nutritional counseling and guidance with a
                      holistic approach. <br /> Our goal is to optimize your
                      health by providing dietary options for a healthier
                      lifestyle.
                    </p>
                    <Link to="/services" class="boxed-btn3">
                      Check Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="single_slider  d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="slider_text ">
                    <h3>
                      {" "}
                      <span>Acupuncture Treatment</span>
                    </h3>
                    <p>
                      Acupuncture is one of the best alternative therapies for
                      pain management
                    </p>
                    <Link to="/services" class="boxed-btn3">
                      Check Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="single_slider  d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="slider_text ">
                    <h3>
                      {" "}
                      <span>Homeopathy</span>
                    </h3>
                    <p>
                      Homeopathy is a therapeutic approach characterized by its
                      non-invasive nature, reliance on natural remedies, and
                      promotion of healing that aligns with the body&#39;s
                      processes.
                    </p>
                    <Link to="/services" class="boxed-btn3">
                      Check Our Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <div class="welcome_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="welcome_info">
                <h2>Welcome to SV Holistic Care</h2>
                <h3>
                  Affordable and Best Care for <br />
                  your Good Health
                </h3>
                <p>
                  With over a decade of experience in alternate care therapies,
                  our mission is to assist individuals in living healthier
                  lives. We provide honest and quality care by educating
                  individuals through nutrition counseling and alternate
                  therapies, tailored to match every individual’s unique health
                  needs.
                </p>
                <Link to="/services" class="boxed-btn3-white-2">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="our_department_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="section_title text-center mb-55">
                <h3>Our Services</h3>
                <p>
                  {" "}
                  Personalized nutrition counseling and alternate care therapies{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-md-6 col-lg-4">
              <div class="single_department">
                <div class="department_thumb">
                  <img src={NutritionImg} alt="" />
                </div>
                <div class="department_content">
                  <h3>Nutrition Counseling</h3>
                  <p>
                    We provide nutritional counseling and guidance with a
                    holistic approach. Our goal is to optimize your health by
                    providing dietary options for a healthier lifestyle.
                  </p>
                  <Link to="/services" class="learn_more">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 col-lg-4">
              <div class="single_department">
                <div class="department_thumb">
                  <img src={AcupunctureImg} alt="" />
                </div>
                <div class="department_content">
                  <h3>Acupuncture</h3>
                  <p>
                    Acupuncture is one of the best alternative therapies for
                    pain management. Acupuncture, derived from traditional
                    Chinese medicine, is a needling technique used to regulate
                    body’s energy flow (Qi).
                  </p>
                  <Link to="/services" class="learn_more">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-6 col-lg-4">
              <div class="single_department">
                <div class="department_thumb">
                  <img src={HomeopathyImg} alt="" />
                </div>
                <div class="department_content">
                  <h3>Homeopathy</h3>
                  <p>
                    Homeopathy is a therapeutic approach characterized by its
                    non-invasive nature, reliance on natural remedies, and
                    promotion of healing that aligns with the body&#39;s
                    processes.
                  </p>
                  <Link to="/services" class="learn_more">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Emergency_contact">
        <div class="conatiner-fluid p-0">
          <div class="row no-gutters">
            <div class="col-xl-6">
              <div class="single_emergency d-flex align-items-center justify-content-center emergency_bg_1 overlay_skyblue">
                <div class="info">
                  <h3>For Any Service Contact</h3>
                </div>
                <div class="info_button">
                  <Link class="boxed-btn3-white">631-278-1960</Link>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="single_emergency d-flex align-items-center justify-content-center emergency_bg_2 overlay_skyblue">
                <div class="info">
                  <h3>Make an Online Appointment</h3>
                </div>
                <div class="info_button">
                  <Link
                    to="/appointment"
                    class="boxed-btn3-white"
                  >
                    Make an Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
