import { useEffect } from "react";
import { Link } from "react-router-dom";
import NutritionLogo from "../img/services/1.png";
import AcupuntureLogo from "../img/services/3.png";
import HomoeopathLogo from "../img/services/2.png";

const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="bradcam_area breadcam_bg bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="bradcam_text">
                <h3>Services</h3>
                <p>
                  <Link to="/home">Home /</Link> Services
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="business_expert_area">
        <div class="business_tabs_area">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <ul class="nav" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="Nutrition-tab"
                      data-toggle="tab"
                      href="#Nutrition"
                      role="tab"
                      aria-controls="Nutrition"
                      aria-selected="true"
                    >
                      Nutrition Counseling
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="Acupuncture-tab"
                      data-toggle="tab"
                      href="#Acupuncture"
                      role="tab"
                      aria-controls="Acupuncture"
                      aria-selected="false"
                    >
                      Acupuncture
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="Homeopathy-tab"
                      data-toggle="tab"
                      href="#Homeopathy"
                      role="tab"
                      aria-controls="Homeopathy"
                      aria-selected="false"
                    >
                      Homeopathy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="border_bottom">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="Nutrition"
                role="tabpanel"
                aria-labelledby="Nutrition-tab"
              >
                <div class="row align-items-center">
                  <div class="col-xl-12 col-md-12">
                    <div class="business_info">
                      <div class="icon">
                        <img src={NutritionLogo} alt="" />
                      </div>
                      <h3>Nutrition Counseling</h3>
                      <p>
                        We provide nutritional counseling and guidance with a
                        holistic approach. Our goal is to optimize your health
                        by providing dietary options for a healthier lifestyle.
                        Our process includes evaluating your current lifestyle
                        and diet followed by a personalized plan to address your
                        specific concerns. Suggested measures include
                        nutritional intervention, referral to other providers,
                        and regular follow-up. Our mission is to help people
                        live healthier lives.
                      </p>
                      <br />
                      <p>
                        The following are some of the areas in which we may
                        assist with nutrition counseling:
                      </p>
                      <br />
                      <ul>
                        <li>Weight Loss</li>
                        <li>Hormonal issues</li>
                        <li>Acute ailments</li>
                        <li>Chronic ailments</li>
                        <li>Migraine</li>
                        <li>Thyroid issues</li>
                        <li>Depression</li>
                        <li>Anxiety</li>
                        <li>Gastric issues</li>
                        <li>Diabetes</li>
                        <li>Asthma</li>
                        <li>Sinus</li>
                        <li>Menopause (Hot flashes)</li>
                        <li>Eating disorder</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="Acupuncture"
                role="tabpanel"
                aria-labelledby="Acupuncture-tab"
              >
                <div class="row align-items-center">
                  <div class="col-xl-12 col-md-12">
                    <div class="business_info">
                      <div class="icon">
                        <img src={AcupuntureLogo} alt="" />
                      </div>
                      <h3>Acupuncture</h3>
                      <p>
                        Acupuncture is one of the best alternative therapies for
                        pain management. Acupuncture, derived from traditional
                        Chinese medicine, is a needling technique used to
                        regulate body's energy flow (Qi). Needling is used to
                        alleviate blockage in energy flow, encourage proper
                        blood circulation, and thus relieving chronic pain. SV
                        Holistic Care offers acupuncture services in-house, such
                        as cupping massage, ear acupuncture, and electrical
                        acupuncture to help clients deal with pain.
                      </p>
                      <br />
                      <p>
                        The following are some of the areas in which we may
                        assist with Acupuncture treatment:
                      </p>
                      <br />
                      <ul>
                        <li>Back pain</li>
                        <li>Sciatica</li>
                        <li>Lower back pain</li>
                        <li>Cervical pain</li>
                        <li>Carpal Tunnel</li>
                        <li>Migraine</li>
                        <li>Knee pain &amp; Arthritis</li>
                        <li>Frozen shoulder</li>
                        <li>Muscle spasm</li>
                        <li>Digestive issues</li>
                        <li>Tennis elbow</li>
                        <li>Fibromyalgia</li>
                        <li>Cold extremities</li>
                        <li>Sinus</li>
                      </ul>
                      <p>We accept all major healthcare insurances.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="Homeopathy"
                role="tabpanel"
                aria-labelledby="Homeopathy-tab"
              >
                <div class="row align-items-center">
                  <div class="col-xl-12 col-md-12">
                    <div class="business_info">
                      <div class="icon">
                        <img src={HomoeopathLogo} alt="" />
                      </div>
                      <h3>Homeopathy</h3>
                      <p>
                        Homeopathy is a therapeutic approach characterized by
                        its non-invasive nature, reliance on natural remedies,
                        and promotion of healing that aligns with the body&#39;s
                        processes. This treatment aims to optimize the
                        body&#39;s natural healing abilities and restore overall
                        well-being by strengthening the immune system. SV
                        Holistic Care prioritizes the individual health status
                        of each patient and diligently focuses on addressing
                        their specific physical and emotional concerns. The
                        individual is administered a customized homoeopathic
                        remedy specifically designed to target their specific
                        ailment. The factors that contribute to these conditions
                        encompass lifestyle choices, personal stressors,
                        allergies, and food sensitivities. During our
                        assessment, we carefully consider these factors in order
                        to determine the most appropriate homoeopathic remedy
                        for restoring your health.
                      </p>
                      <br />
                      <p>
                        The following are some of the areas in which we may
                        assist with homeopathic medicine:
                      </p>
                      <br />
                      <ul>
                        <li>Sinus</li>
                        <li>Seasonal Allergies</li>
                        <li>Flu</li>
                        <li>Exam stress</li>
                        <li>Anxiety</li>
                        <li>Menstrual Issues</li>
                        <li>Headache</li>
                        <li>Constipation</li>
                        <li>Gas and bloating issues</li>
                        <li>Respiratory issues</li>
                        <li>Acid reflux</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
