import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import Header from "./pages/header";
import NavComponent from "./components/navComponent";
import Footer from "./pages/footer";
import Home from "./pages/home";
import AboutUs from "./pages/about";
import Whysvhc from "./pages/whysvhc";
import Services from "./pages/services";
import Blog from "./pages/blog";
import SingleBlog from "./pages/singleblog";
import Contact from "./pages/contact";
import NotFound from "./pages/notfound";
import Appointment from "./pages/appointment";
import Login from "./auth/login";
import Logout from "./auth/logout";
import AppointmentList from "./pages/admin/appointmentList";
import ContactList from "./pages/admin/contactList";
import AdminHome from "./pages/admin/home";
import { AuthProvider } from "./auth/authContext";

const queryClient = new QueryClient();

function App() {
   const Layout = () => {
  return (
    <div className="App">
      <AuthProvider>
        <header className="App-header">
          <NavComponent />
        </header>
        <div className="contentContainer">
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </div>
        <Footer />
      </AuthProvider>
    </div>
  );

}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/whysvhc",
        element: <Whysvhc />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/singleblog",
        element: <SingleBlog />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/appointment",
        element: <Appointment />
      },
      {
        path: "*",
        element: <NotFound />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/logout",
        element: <Logout />
      },
      {
        path: "/admin/adminHome",
        element: <AdminHome />
      },
      {
        path: "/admin/contactList",
        element: <ContactList />
      },
      {
        path: "/admin/appointmentList",
        element: <AppointmentList />
      }
    ],
  },
]);

return <RouterProvider router={router} />;

}

export default App;
