import React from "react";
import {  useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "../../auth/authContext";
import { doSignOut } from "../../auth/auth";
import Link from "@mui/material/Link";

const AdminHome = () => {
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();

  return (
    <>
      {!userLoggedIn && <Navigate to={"/login"} replace={true} />}

      <div class="bradcam_area breadcam_bg_2 bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Admin Dashboard</h3>
                <p>
                  <Link href="/home" underline="hover">
                    Home
                  </Link>{" "}
                  / Admin Dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-8">
              <div class="about_info">
                <h2>
                  {" "}
                  Use the below links to view the appointments and contact
                  details
                </h2>
                <ul>
                  <li>
                    <Link href="/admin/appointmentList" underline="hover">
                      <h4>Appointment List</h4>
                    </Link>
                  </li>
                  <li>
                    <Link href="/admin/contactList" underline="hover">
                      <h4>Contact List</h4>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4">
              <button
                type="button"
                class="boxed-btn3-white-2"
                onClick={() => {
                  doSignOut().then(() => {
                    navigate("/login");
                  });
                }}
              >
                SignOut
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
