import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Hamburger } from "../img/hamburger.svg";
import logo from "../img/SVHolisticCare1.png";
import "./navbar.css";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const closeNavBar = () => {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="home">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="home" onClick={closeNavBar}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="about" onClick={closeNavBar}>
                About
              </NavLink>
            </li>
            <li>
              <NavLink to="services" onClick={closeNavBar}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to="whysvhc" onClick={closeNavBar}>
                WhySVHC
              </NavLink>
            </li>
            <li>
              <NavLink to="contact" onClick={closeNavBar}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div class="appointmenmt">
          <div class="book_btn d-none d-lg-block">
            <button type="button" class="boxed-btn3-white-2">
              <Link to="appointment">Make an Appointment</Link>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
