import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./dataTable.css";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const DataTable = (props) => {
 /*  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: (params) => {
      return (
        <div className="action">
          <Link to={`/${props.slug}/${params.row.Id}`}>
            <img src="/view.svg" alt="" />
          </Link>
        </div>
      );
    },
  }; */

  return (
    <div className="dataTable">
      <DataGrid
        className="dataGrid"
        rows={props.rows}
        columns={[...props.columns]}
        //getRowHeight={() => 'auto'}
        getRowId={(row) => uuidv4()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: false,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        disableColumnFilter
        disableDensitySelector
        disableColumnSelector
      />
    </div>
  );
};

export default DataTable;
