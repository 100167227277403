import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  doSignInWithEmailAndPassword
} from "../auth/auth";
import { useAuth } from "../auth/authContext"

const Login = () => {
  const navigate = useNavigate();

  const { userLoggedIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (errors) => {};

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onlogin = async () => {
    if(!isSigningIn) {
      await doSignInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          setIsSigningIn(true);
          reset();
          navigate("/admin/adminHome");
        })
        .catch((error) => {
          const errorMessage = error.message;
          setErrorMessage(errorMessage);
          reset();
        });
    }
  };

  return (
    <>
      {userLoggedIn && <Navigate to={"/admin/adminHome"} replace={true} />}

      <div class="bradcam_area breadcam_bg_2 bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Admin Login</h3>
                <p>
                  <Link to="/home">Home /</Link> Admin Login
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="contact-section">
        <form onSubmit={handleSubmit(onlogin, handleError)}>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div className="text-danger">{errorMessage}</div>
                <h2 class="contact-title">Login as Admin</h2>
              </div>
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        {...register("email", {
                          required: "Email is required",
                        })}
                        class="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter your Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.email && errors.email.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <input
                        {...register("password", {
                          required: "Password is required",
                        })}
                        class="form-control valid"
                        name="password"
                        id="password"
                        type="password"
                        placeholder="Enter the password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.password && errors.password.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button
                    type="submit"
                    class="button button-contactForm boxed-btn"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default Login;
