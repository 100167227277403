import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { db } from "../db/firebase";
import { collection, addDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

const Appointment = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleError = (errors) => {};
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const yesterday = dayjs().subtract(1, "day");
  const today = dayjs();

  const handleAppDateChange = (newDate) => {
    setAppointmentDate(new Date(newDate));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const appointmentCollectionRef = collection(db, "appointmentdata");

  const saveData = async () => {
    const createDate = new Date();

    const payload = {
      name: name,
      email: email,
      phone: phone,
      appointmentDate: appointmentDate,
      service: service,
      message: message,
      createdDate: createDate,
    };

    try {
      await addDoc(appointmentCollectionRef, payload)
        .then((docRef) => {
          setsuccessMessage("Your appointment requrest received.");
          reset();
        })
        .catch((error) => {
          alert(error);
        });
    } catch (error) {
      alert("Error - ", error);
      console.log(error);
    }
  };

  return (
    <>
      <div class="bradcam_area breadcam_bg_2 bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Appointment</h3>
                <p>
                  <Link to="/home">Home /</Link> Appointment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="contact-section">
        <form onSubmit={handleSubmit(saveData, handleError)}>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div className="text-success">{successMessage}</div>
                <h2 class="contact-title">Make an Appointment</h2>
              </div>
              <div class="col-lg-8">
                <p>
                  <small className="text-danger">* Required</small>
                </p>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputName">Name *</label>
                      <input
                        {...register("name", { required: "Name is required" })}
                        class="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.name && errors.name.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail">Email address *</label>
                      <input
                        {...register("email", {
                          required: "Email is required",
                        })}
                        class="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter your Email Address to contact"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.email && errors.email.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="exampleInputPhone">Phone Number *</label>
                      <input
                        {...register("phone", {
                          required: "Phone is required",
                        })}
                        class="form-control"
                        name="phone"
                        id="phone"
                        type="text"
                        placeholder="Enter Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.phone && errors.phone.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="exampleInputDate">Appointment Date *</label>
                      <br />
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year", "month", "day"]}
                            defaultValue={[yesterday, today]}
                            disablePast
                            value={dayjs(appointmentDate)}
                            onChange={handleAppDateChange}
                            name="AnnouncementDate"
                          />
                        </LocalizationProvider>
                      </>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleInputService">Service *</label>
                      <select
                        {...register("service", {
                          required: "Please select a service",
                        })}
                        class="form-control"
                        id="service"
                        onChange={(e) => setService(e.target.value)}
                      >
                        <option data-display="Select Service">
                          Select a Service
                        </option>
                        <option value="Nutrition Counseling">
                          Nutrition Counseling
                        </option>
                        <option value="Acupuncture">Acupuncture</option>
                        <option value="Homoeopathy">Homoeopathy</option>
                      </select>
                      <small className="text-danger">
                        {errors?.service && errors.service.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleInputMessage">Brief Message *</label>
                      <textarea
                        {...register("message", {
                          required: "Message is required",
                        })}
                        class="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="3"
                        placeholder="Please provide us a brief message"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <small className="text-danger">
                        {errors?.message && errors.message.message}
                      </small>
                    </div>
                  </div>
                </div>
                <p>
                  <small className="text-danger">
                    * We will contact you regarding appointment timings.
                  </small>
                </p>
                <div class="form-group mt-3">
                  <button
                    type="submit"
                    class="button button-contactForm boxed-btn"
                  >
                    Confirm Your Appointment
                  </button>
                </div>
              </div>

              <div class="col-lg-3 offset-lg-1">
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </i>
                  </span>
                  <div class="media-body">
                    <p>
                      For Nutrition Counseling and Homoeopathy service we
                      provide zoom consultation and for Acupuncture an in-house
                      consultation in the following locations:
                    </p>
                    <p>
                      466 Moriches Road, St James NY 11780 <br />
                      <br />
                      27 N Country Road, Shoreham NY 11786
                    </p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faPhone} />
                    </i>
                  </span>
                  <div class="media-body">
                    <h3>+1 631-278-1960</h3>
                    <p>Mon to Fri 9am to 6pm</p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </i>
                  </span>
                  <div class="media-body">
                    <h3>svacupuncturewellness@gmail.com</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default Appointment;
