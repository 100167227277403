import { Link } from "react-router-dom";
import { useEffect } from "react";

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="bradcam_area breadcam_bg bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="bradcam_text">
                <h3>About Us</h3>
                <p>
                  <Link to="/home">Home /</Link> About
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="about_info">
                <h2>Welcome to SV Holistic Care</h2>
                <h3>
                  Affordable and Best Care for <br />
                  your Good Health
                </h3>
                <p>
                  With over a decade of experience in alternate care therapies,
                  our mission is to assist individuals in living healthier
                  lives. We provide honest and quality care by educating
                  individuals through nutrition counseling and alternate
                  therapies, tailored to match every individual’s unique health
                  needs.
                </p>
                <Link to="/services" class="boxed-btn3-white-2">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
