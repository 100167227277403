import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <div class="bradcam_area breadcam_bg bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="bradcam_text">
                <h3>Page Not Found</h3>
                <p>
                  <Link to="/home">Home /</Link> Page Not Found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about_area">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="about_info">
                <h2>Oops! You seem to be lost</h2>
                <p>
                  <p>Here are some helpful links:</p>
                  <p>
                    <Link to="/">Home</Link>
                  </p>
                  <p>
                    <Link to="/contact">Contact</Link>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
