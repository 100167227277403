import { Link } from "react-router-dom";
import logo from "../img/SVHolisticCare_footer.png";

const footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer class="footer">
      <div class="footer_top">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-lg-3">
              <div class="footer_widget">
                <div class="footer_logo">
                  <Link to="home">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <p>
                  Personalized nutrition counseling and alternate care
                  therapies
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-4">
              <div class="footer_widget">
                <h3 class="footer_title">Services</h3>
                <ul>
                  <li>
                    <Link to="services">Nutrition Counseling</Link>
                  </li>
                  <li>
                    <Link to="services">Acupuncture</Link>
                  </li>
                  <li>
                    <Link to="services">Homeopathy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-md-6 col-lg-2">
              <div class="footer_widget">
                <h3 class="footer_title">Useful Links</h3>
                <ul>
                  <li>
                    <Link to="about">About</Link>
                  </li>
                  <li>
                    <Link to="services">Services</Link>
                  </li>
                  <li>
                    <Link to="whysvhc">Why SVHC</Link>
                  </li>
                  <li>
                    <Link to="blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="appointment">Appointment</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-3">
              <div class="footer_widget">
                <h3 class="footer_title">Address</h3>
                <p>
                  We provide the option of either a zoom consultation or an
                  in-house consultation in the following locations:
                </p>
                <p>
                  466 Moriches Road, St James NY 11780 <br />
                  <br />
                  27 N Country Road, Shoreham NY 11786
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-right_text">
        <div class="container">
          <div class="footer_border"></div>
          <div class="row">
            <div class="col-xl-12">
              <p class="copy_right text-center">
                Copyright SV Holistic Care &copy;
                {year} All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
