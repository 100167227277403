import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
 import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAbrMMqzHZMrBGausKQQzakAqzMsiv-XpA",
  authDomain: "svhcapp-23952.firebaseapp.com",
  projectId: "svhcapp-23952",
  storageBucket: "svhcapp-23952.appspot.com",
  messagingSenderId: "39297007713",
  appId: "1:39297007713:web:1717f1aee608ee8eaaea20",
  measurementId: "G-9PJFE13XGR",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);