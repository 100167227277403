import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../db/firebase";
import { collection, addDoc } from "firebase/firestore"
import { useForm } from "react-hook-form";

const Contact = () => {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleError = (errors) => {};

  const[name, setName] = useState('');
  const[email, setEmail] = useState('');
  const[subject, setSubject] = useState('');
  const[message, setMessage] = useState('');
  const[successMessage, setsuccessMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contactCollectionRef = collection(db, "contactdata");

  const saveData = async () => {  
    const createDate = new Date();

    const payload = {
      name: name,
      email: email,
      subject: subject,
      message: message,
      createdDate: createDate
    }

    try {
    await addDoc(contactCollectionRef, payload)
      .then(docRef => {
          setsuccessMessage("Data saved successfully")
          reset();
      })
      .catch(error => {
        alert(error);
      })
    } catch(error) {
      alert("Error - ", error);
      console.log(error);
    }
  
  }

  return (
    <>
      <div class="bradcam_area breadcam_bg_2 bradcam_overlay">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="bradcam_text">
                <h3>Contact</h3>
                <p>
                  <Link to="/home">Home /</Link> Contact
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="contact-section">
        <form onSubmit={handleSubmit(saveData, handleError)}>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div className="text-success">{successMessage}</div>
                <h2 class="contact-title">Get in Touch</h2>
              </div>
              <div class="col-lg-8">
                <p>
                  <small className="text-danger">* Required</small>
                </p>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputName">Name *</label>
                      <input
                        {...register("name", { required: "Name is required" })}
                        class="form-control valid"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.name && errors.name.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail">Email address *</label>
                      <input
                        {...register("email", {
                          required: "Email is required",
                        })}
                        class="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Enter your Email Address to contact"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <small className="text-danger">
                        {errors?.email && errors.email.message}
                      </small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleInputSubject">Subject</label>
                      <input
                        {...register("subject")}
                        class="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Purpose for contact us"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleInputMessage">Brief Message * </label>
                      <textarea
                        {...register("message", {
                          required: "Message is required",
                        })}
                        class="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="3"
                        placeholder="Please provide us a brief message"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <small className="text-danger">
                        {errors?.message && errors.message.message}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <button
                    type="submit"
                    class="button button-contactForm boxed-btn"
                  >
                    Send
                  </button>
                </div>
              </div>

              <div class="col-lg-3 offset-lg-1">
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faLocationDot} />
                    </i>
                  </span>
                  <div class="media-body">
                    <p>
                      We provide the option of either a zoom consultation or an
                      in-house consultation in the following locations:
                    </p>
                    <p>
                      466 Moriches Road, St James NY 11780 <br />
                      <br />
                      27 N Country Road, Shoreham NY 11786
                    </p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faPhone} />
                    </i>
                  </span>
                  <div class="media-body">
                    <h3>+1 631-278-1960</h3>
                    <p>Mon to Fri 9am to 6pm</p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </i>
                  </span>
                  <div class="media-body">
                    <h3>svacupuncturewellness@gmail.com</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default Contact;
